import * as React from "react";

import SmartLayout from "theme/layouts/SmartLayout";
import prismicConfig from "config/prismic";
import PrismicPreview from "theme/modules/Prismic/PrismicPreview";

const PrismicLayout = (props) => {
  const [inspect, setInspect] = React.useState(false);

  React.useEffect(() => {
    setInspect(localStorage.getItem("prismic-inspect") === "true");
  }, []);

  return (
    <div className={inspect ? "inspect-demo" : ""}>
      <SmartLayout {...props} />
      <PrismicPreview />
      <div
        style={{
          position: "fixed",
          bottom: 10,
          right: 10,
          cursor: "pointer",
          zIndex: 99,
        }}
        onClick={() =>
          setInspect((curr) => {
            return localStorage.setItem("prismic-inspect", !curr) || !curr;
          })
        }
      >
        {inspect && `💡`} Inspect
      </div>
    </div>
  );
};

const Layout = (props) => {
  const pathname = props.location.pathname;
  const showPrismicLayout = prismicConfig.matchRoute(pathname);

  if (!showPrismicLayout) {
    return <SmartLayout {...props} />;
  }

  return <PrismicLayout {...props} />;
};

export default Layout;
