import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import LinkList from "theme/components/molecules/LinkList";
import ErrorBoundary from "theme/components/helpers/ErrorBoundary";
import { contactFooterLinks, userAccountFooterLinks } from "./footerLinkLists";
import Brand from "./Brand";

const BottomBar = (props) => (
  <div className="bottombar">
    <div className="container bottombar__wrapper">
      <div className="bottombar__aside">
        <Brand />
      </div>
      <div className="bottombar__links">
        <div className="bottombar__list">
          <LinkList
            title={userAccountFooterLinks.title}
            list={userAccountFooterLinks.links}
          />
        </div>
        <div className="bottombar__list">
          <ErrorBoundary>
            {({ hasError }) =>
              hasError ? null : (
                <LinkList
                  title={
                    <FormattedMessage
                      id="layouts.Footer.categories"
                      defaultMessage="Categories"
                    />
                  }
                  list={
                    props.categories.loading
                      ? []
                      : props.categories.navigationMenu
                  }
                />
              )
            }
          </ErrorBoundary>
        </div>
        <div className="bottombar__list">
          <LinkList
            title={contactFooterLinks.title}
            list={[
              ...contactFooterLinks.links,
              {
                name: "Blog",
                path: "/blog",
              },
            ]}
          />
        </div>
      </div>
    </div>
  </div>
);

BottomBar.propTypes = {
  categories: PropTypes.object,
  accountDetails: PropTypes.array,
  contactList: PropTypes.array,
};

export default BottomBar;
